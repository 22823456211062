.ImDec_Container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.ImDec_Main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  background-color: #fff;
  border-radius: 10px;
}

.Dec_Head_Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  width: 100%;
  padding: 50px;
}

.Dec_Left {
  display: flex;
  gap: 32px;
  align-items: center;
}

.Dec_Path_Container {
  display: flex;
  align-self: center;
  gap: 12px;
  color: #6c757d;
}

.Dec_Path_Container a:visited,
.Dec_Path_Container a:active,
.Dec_Path_Container a {
  text-decoration: none;
  color: #007bff;
}

.Import_Upload {
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
}
form {
  width: 100%;
}

.Form_Upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
}
.Form_Upload_Text {
  font-size: 16px;
}
.Form_Upload_File {
  display: flex;
  gap: 12px;
  align-items: center;
}

.Form_Upload label.custom-file-upload {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  background-color: #007bff;
  color: white;
  padding: 7px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Form_Upload input[type="file"] {
  display: none;
}

.Form_Upload label.custom-file-upload:hover {
  background-color: #0056b3;
}

.file-info {
  font-size: 16px;
  color: red;
}

.Imp_Add_Dec_Button {
  display: flex;
  align-items: center;
  justify-content: center;
  right: 50px;
  top: 173px;
  padding: 12px;
  text-decoration: none;
  gap: 8px;
  border: none;
  font-size: 18px;
  background-color: #28a745;
  cursor: pointer;
  color: #fff;
  width: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 160px;
  height: 50px;
  background-color: #57b846;
  border-radius: 25px;
  font-family: Poppins-Regular;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  align-self: flex-end;
}

.Imp_Add_Dec_Button:hover {
  background-color: #218838;
}

.Imp_Add_Dec_Button:disabled {
  background-color: #12461d;
  cursor: not-allowed;
}

.upload-notes {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #6c757d;
  width: 100%;
}
.upload-notes span {
  color: red;
}

/* Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 40px;
  border-radius: 5px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: auto;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: red;
}

.popup-content h3 {
  margin-bottom: 20px;
  width: 100%;
}

.popup-content button {
  padding: 10px 20px;
  background: #218838;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-content button:hover {
  background: #57b846;
}
