.UserLoggedSvg {
  width: 30px;
}
.Toggle_Menu_Container {
  position: relative;
}
.Toggle_Menu_Container button {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
}
.Toggle_User {
  color: #28a745;
}

.Toggle_Menu {
  position: absolute;
  right: 0px;
  top: 40px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  padding: 20px 0px;
  display: flex;
  min-width: 250px;
  width: max-content;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.Toggle_LogOut {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 120px;
  height: 40px;
  background-color: red;
  border-radius: 25px;

  font-family: Poppins-Regular;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.Toggle_LogOut:hover {
  background-color: #333333;
}
.Add_UserLogged {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.UserNotLogged {
  color: red;
  font-size: 16px;
  font-weight: bold;
  gap: 8px;
  display: flex;
  align-items: center;
}
